import moment from 'moment';
import ApiService from './apiService';

export interface IUserSorter {
  field: string;
  order: 'ASC' | 'DESC';
}

const UserService = {
  getAll: async (params: {
    page: number;
    sort: IUserSorter;
    orderBy?: string;
    filter?: {
      invited: true;
    };
    searchTerm?: string;
    activeUser?: string;
  }): Promise<{ data: User[]; total: number }> => {
    const response = await ApiService.get(`/users`, {
      page: params.page,
      sortField: params.sort.field,
      sortOrder: params.sort.order,
      invited: params.filter && params.filter.invited ? 'true' : 'false',
      searchTerm: params.searchTerm,
      activeUser: params.activeUser,
    });
    return response.data;
  },
  getAllStudents: async (params: {
    page: number;
    sort: IUserSorter;
    orderBy?: string;
    filter?: {
      invited: true;
    };
    searchTerm?: string;
    subscriptionStatusUser?: any;
  }): Promise<{ data: User[]; total: number }> => {
    const response = await ApiService.get(`/students-new`, {
      page: params.page,
      sortField: params.sort.field,
      sortOrder: params.sort.order,
      // invited: params.filter && params.filter.invited ? 'true' : 'false',
      searchTerm: params.searchTerm,
      subscriptionStatusUser: params.subscriptionStatusUser,
    });
    return response.data;
  },
  getStudents: async (searchTerm?: string): Promise<User[]> => {
    const response = await ApiService.get(`/students`, { searchTerm });
    return response.data;
  },
  getMediaReview: async (searchTerm?: string): Promise<User[]> => {
    const response = await ApiService.get(`/objective-media-feedback`, {
      searchTerm,
    });
    return response.data;
  },
  deleteMediaFeedback: async (id: number): Promise<{ success: true }> => {
    const response = await ApiService.delete(`/objective-media-feedback/${id}`);
    return response.data;
  },
  getOne: async (id: number): Promise<User> => {
    const response = await ApiService.get(`/users/${id}`);
    return response.data;
  },
  getOneStudent: async (id: number): Promise<User> => {
    const response = await ApiService.get(`/users-student/${id}`);
    return response.data;
  },

  delete: async (id: number): Promise<{ success: true }> => {
    const response = await ApiService.delete(`/users/${id}`);
    return response.data;
  },

  deleteSoft: async (id: number): Promise<{ success: true }> => {
    const response = await ApiService.delete(`/users-soft/${id}`);
    return response.data;
  },

  create: async (
    data: Pick<User, 'email' | 'firstName' | 'lastName'> & {
      nextPaymentDate?: moment.Moment | null;
    },
  ): Promise<User> => {
    const response = await ApiService.post('/auth/signup', {
      ...data,
      password: 'temporaryPass',
      invited: true,
      nextPaymentDate: data.nextPaymentDate
        ? data.nextPaymentDate.toDate()
        : data.nextPaymentDate,
    });
    return response.data;
  },

  update: async (id: number, data: User): Promise<User> => {
    const response = await ApiService.put(`/users/${id}`, data);
    return response.data;
  },
  updateStudent: async (id: number, data: User): Promise<User> => {
    const response = await ApiService.put(`/users/student/${id}`, data);
    return response.data;
  },
  updateDocuments: async (
    id: number,
    documents: Partial<UserDocument>[],
  ): Promise<User> => {
    const response = await ApiService.put(`/users/${id}/documents`, {
      documents,
    });
    return response.data;
  },

  addNotesCreateAdminForStudent: async (
    id: number,
    notesCreateAdminForStudents: Partial<UserNotesCreateAdminForStudent>[],
  ): Promise<User> => {
    const response = await ApiService.put(
      `/users/${id}/notes-create-admin-student`,
      {
        notesCreateAdminForStudents,
      },
    );
    return response.data;
  },
  updateNotesCreateAdminForStudent: async (
    studentId: number,
    notesUpdate: any,
  ): Promise<User> => {
    const response = await ApiService.put(
      `/users/${studentId}/notes-update-admin-student`,
      {
        notesUpdate,
      },
    );
    return response.data;
  },
  deleteNotesCreateAdminForStudent: async (
    id: number,
    noteId: number,
  ): Promise<User> => {
    const response = await ApiService.delete(`/users/${id}/notes/${noteId}`);
    return response.data;
  },
  courseDeleteStudent: async (id: number, courseId: number): Promise<any> => {
    const response = await ApiService.delete(`/users/${id}/course/${courseId}`);
    return response.data;
  },
  courseAddStudent: async (id: number, courseId: number): Promise<any> => {
    const response = await ApiService.post(`/users/add/course`, {
      id,
      courseId,
    });
    return response.data;
  },
  deleteUserDegree: async (id: number, degreeId: number): Promise<any> => {
    const response = await ApiService.delete(
      `/users/${id}/degrees/${degreeId}`,
    );
    return response.data;
  },
  startCourseForStudent: async (id: number, courseId: number): Promise<any> => {
    const response = await ApiService.post(`/users/start/course`, {
      id,
      courseId,
    });
    return response.data;
  },
  getAllCourse: async (): Promise<User> => {
    const response = await ApiService.get(`/courses/all`);
    return response.data;
  },
  forgotPassword: async (email: string): Promise<any> => {
    const response = await ApiService.post(`/auth/forgot-password`, {
      email,
    });
    return response.data;
  },

  resetPassword: async (token: string, password: string): Promise<any> => {
    const response = await ApiService.post(`/auth/reset-password`, {
      token,
      password,
    });
    return response.data;
  },

  getInstructors: async (): Promise<User[]> => {
    const response = await ApiService.get(`/teachers`);
    return response.data;
  },

  getScheduledCalls: async (): Promise<ScheduledCall[]> => {
    const response = await ApiService.get(
      `/users/current-user/scheduled-calls`,
    );
    return response.data;
  },

  scheduleCall: async (timestamp: number): Promise<ScheduledCall> => {
    const response = await ApiService.post(
      `/users/current-user/scheduled-calls`,
      {
        timestamp,
      },
    );
    return response.data;
  },

  getUserAgreements: async (): Promise<UserAgreement[]> => {
    const response = await ApiService.get(`/users/current-user/agreements`);
    return response.data;
  },

  signAgreement: async (id: number): Promise<any> => {
    const response = await ApiService.put(
      `/users/current-user/agreements/${id}`,
      {},
    );
    return response.data;
  },

  getInvitedUsers: async (): Promise<User[]> => {
    const response = await ApiService.get(`/invited-users`);
    return response.data;
  },

  getAllUserNotes: async (id: number): Promise<UserCourse[]> => {
    const response = await ApiService.get(`/users/${id}/notes`);
    return response.data;
  },

  deleteCard: async (cardId: string): Promise<{ success: true }> => {
    const response = await ApiService.delete(
      `/users/current-user/cards/${cardId}`,
    );
    return response.data;
  },

  cancelSubscription: async (): Promise<{ success: true }> => {
    const response = await ApiService.delete(
      `/users/current-user/subscription`,
    );
    return response.data;
  },
  cancelSubscriptionTest: async (
    studentId: any,
  ): Promise<{ success: true }> => {
    const response = await ApiService.delete(
      `/users/current-user/subscription/${studentId}`,
    );
    return response.data;
  },

  resumeSubscription: async (): Promise<{ success: true }> => {
    const response = await ApiService.post(
      `/users/current-user/resume-subscription`,
      {},
    );
    return response.data;
  },

  getBillingInfo: async (userId: number): Promise<BillingInfo> => {
    const response = await ApiService.get(`/users/${userId}/billing-info`);
    return response.data;
  },

  updateCredits: async (
    id: number,
    credits: Partial<Credit>[],
  ): Promise<User> => {
    const response = await ApiService.put(`/users/${id}/credits`, {
      credits,
    });
    return response.data;
  },

  deleteCredit: async (id: number, creditId: number): Promise<User> => {
    const response = await ApiService.delete(
      `/users/${id}/credits/${creditId}`,
    );
    return response.data;
  },
  getChats: async (id: number): Promise<User> => {
    const response = await ApiService.get(`/chats`);
    return response.data;
  },
  getUserChats: async (id: number): Promise<User> => {
    const response = await ApiService.get(`/chats/${id}`);
    return response.data;
  },
  sendMessage: async (
    chatId: number,
    text: any,
    sendChatUserId: any,
  ): Promise<User> => {
    const response = await ApiService.post(`/chats/${chatId}/messages`, {
      text,
      sendChatUserId,
    });
    return response.data;
  },

  userCourseStatus: async (data: object): Promise<User> => {
    const response = await ApiService.post(`/users/update-course-status`, data);
    return response.data;
  },
};

export default UserService;
