import React, { useState, useEffect, useRef, useMemo } from 'react';
import Video, { Room, Participant as TwilioParticipant } from 'twilio-video';
import moment from 'moment';
import { LeftCircleTwoTone, RightCircleTwoTone } from '@ant-design/icons';
import {
  Modal,
  Row,
  Col,
  Spin,
  Button,
  Carousel,
  Tabs,
  Layout,
  Input,
} from 'antd';
import { isUserInstructor } from '@helpers';
import OnboardingComponent from '@components/onboarding/Onboarding';
import { VideoCallType, Role } from '@types';
import ApiService from '@services/apiService';
import { StudentCourseProgress } from '@screens/instructor/studentCourseProgress/StudentCourseProgress';
import { AxiosResponse } from 'axios';
import { StudentCourseReview } from '@screens/instructor/studentCourseReview/StudentCourseReview';
import UserService from '@services/userService';
import { ConfirmCourseHearingForm } from './ConfirmCourseHearingForm';
import Participant from './Participant';
import { CourseHearingScript } from './CourseHearingScript';

const { TabPane } = Tabs;
const { Header, Footer, Sider, Content } = Layout;

interface VideoChatModalProps {
  videoCall: VideoCall;
  token: string;
  user: User;
  handleLogout?: () => void;
  setVideoCall: (videoCall: VideoCall) => void;
}

const isUserRegistered = (onboarding: Onboarding) => onboarding.completed;

const VideoChatModal: React.FC<VideoChatModalProps> = ({
  user,
  videoCall,
  token,
  handleLogout,
  setVideoCall,
}) => {
  const [room, setRoom] = useState<Room | null>(null);
  const [registered, setRegistered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [photoLoading, setPhotoLoading] = useState(false);
  const [participants, setParticipants] = useState<TwilioParticipant[]>([]);
  const [photos, setPhotos] = useState<Photo[]>(videoCall.student.photos);
  const carouselEl = useRef<any>(null);
  const [newChatVisible, setNewChatVisible] = useState(false);
  const [message, setMessage] = useState<string>();
  const [interval, setIntervalRef] = useState<NodeJS.Timeout>();
  const [selectedChat, setSelectedChat] = useState([]);
  const [chatId, setChatId] = useState();
  const confirmed = useMemo(() => {
    let confirmedCall;
    if (!videoCall.course) {
      return true;
    }

    if (isUserInstructor(user)) {
      confirmedCall = videoCall.confirmedExpert;
    } else {
      confirmedCall = videoCall.confirmedStudent;
    }

    return confirmedCall;
  }, [videoCall, user]);

  const remoteParticipants = participants.map(participant => (
    <Participant key={participant.sid} participant={participant} remote />
  ));

  const confirmCourseHearing = () => {
    setLoading(true);
    ApiService.post(`/video-calls/${videoCall.id}/confirm`, {}).then(
      (response: AxiosResponse<VideoCall>) => {
        setLoading(false);
        setVideoCall(response.data);
      },
    );
  };

  const registerStudent = () => {
    setLoading(true);
    ApiService.post(`/users/${videoCall.student.id}/register`, {}).then(() => {
      setRegistered(true);
      setLoading(false);
    });
  };

  useEffect(() => {
    UserService.getChats(user.id).then((d: any) => {
      if (d.length > 0) {
        for (let i = 0; i < d.length; i++) {
          const chat = d[i];
          const chatUser = d[i].users.find(
            (u: any) => u.id == videoCall.student.id,
          );
          if (
            d[i].users[0].id == videoCall.student.id &&
            d[i].users[1].id == videoCall.instructor.id
          ) {
            if (chat.messages.length > 0) {
              setSelectedChat(chat.messages);
              setChatId(chat.id);
            }
            return;
          }
          /* if (chatUser) {
            if (chat.messages.length > 0) {
              setSelectedChat(chat.messages);
              setChatId(chat.id);
            }
            return;
          } */
        }
      }
    });
    const participantConnected = (participant: TwilioParticipant) => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    };
    const participantDisconnected = (participant: TwilioParticipant) => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant),
      );
    };

    if (confirmed) {
      Video.connect(token, {
        name: `${videoCall.instructor.email}-${videoCall.student.email}-${videoCall.course.name}`,
      }).then(videoRoom => {
        setRoom(videoRoom);
        videoRoom.on('participantConnected', participantConnected);
        videoRoom.on('participantDisconnected', participantDisconnected);
        videoRoom.participants.forEach(participantConnected);
      });
    }

    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.disconnect();
          return null;
        }
        return currentRoom;
      });
    };
  }, [videoCall, token]);
  const isMyMessage = (message: Message) => message.userId === user.id;
  const handleSendMessage = (value: string) => {
    if (selectedChat && chatId) {
      setMessage(undefined);
      let sendChatUserId;
      if (isUserInstructor(user)) {
        sendChatUserId = videoCall.instructor.id;
      } else {
        sendChatUserId = videoCall.student.id;
      }
      UserService.sendMessage(chatId, value, sendChatUserId).then((d: any) => {
        // console.log('sendMessage', d);
        getUserChats();
      });
    }
  };
  if (chatId && !interval) {
    const int = setInterval(() => {
      getUserChats();
    }, 5000);
    setIntervalRef(int);
  }

  const getUserChats = () => {
    if (!chatId) return;
    UserService.getUserChats(chatId).then((d: any) => {
      if (d && d.messages) {
        setSelectedChat(d.messages);
      }
    });
  };
  const takePhoto = async () => {
    const canvas = document.createElement('canvas');
    canvas.width = 640;
    canvas.height = 480;
    const ctx = canvas.getContext('2d');
    const video = document.getElementById('remote-video') as CanvasImageSource;

    if (ctx && video) {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    }
    const dataUrl = canvas.toDataURL('image/jpeg');

    try {
      setPhotoLoading(true);
      const response = await ApiService.post(
        `/video-calls/${videoCall.id}/photo`,
        {
          dataUrl,
        },
      );
      setPhotos([...photos, response.data]);

      if (carouselEl && carouselEl.current) {
        carouselEl.current.goTo(photos.length);
      }

      setPhotoLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      visible
      width="90%"
      footer={false}
      destroyOnClose
      maskClosable={false}
      closable={false}
    >
      {!confirmed ? (
        <ConfirmCourseHearingForm
          user={user}
          confirmCourseHearing={confirmCourseHearing}
          loading={loading}
        />
      ) : (
        <div className="room">
          <h2>{videoCall.course ? 'Course Hearing' : 'Video Call'}</h2>
          <Row gutter={16}>
            <Col span={12}>
              {room ? (
                <Participant
                  key={room.localParticipant.sid}
                  participant={room.localParticipant}
                />
              ) : (
                ''
              )}
            </Col>
            <Col span={12}>
              {!participants.length && (
                <p
                  style={{
                    textAlign: 'center',
                    display: 'block',
                    marginTop: '80px',
                  }}
                >
                  <Spin />
                  <br />{' '}
                  {isUserInstructor(user)
                    ? 'Waiting for student to connect...'
                    : 'Waiting for instructor to connect...'}
                </p>
              )}
              <div className="remote-participants">{remoteParticipants}</div>
            </Col>
            <Col span={24}>
              <div style={{ textAlign: 'right' }}>
                <Button
                  danger
                  onClick={() => {
                    room && room.disconnect();
                    handleLogout && handleLogout();
                  }}
                >
                  Hang up
                </Button>
              </div>
            </Col>
          </Row>
          {user.role !== Role.Student && (
            <Button
              type="default"
              onClick={() => takePhoto()}
              loading={photoLoading}
            >
              Take photo
            </Button>
          )}
          {videoCall.type === VideoCallType.OnboardingCall &&
            videoCall.student.onboarding &&
            user.role !== Role.Student && (
              <OnboardingComponent
                user={user}
                onboarding={videoCall.student.onboarding}
              />
            )}

          {user.role !== Role.Student && (
            <Tabs
              defaultActiveKey={
                videoCall.student.onboarding &&
                isUserRegistered(videoCall.student.onboarding)
                  ? 'course'
                  : 'onboarding'
              }
            >
              <TabPane tab="Course info" key="course">
                <StudentCourseProgress
                  courseId={videoCall.course.id}
                  studentId={videoCall.student.id}
                />
              </TabPane>
              <TabPane tab="Script" key="script" style={{ padding: '0 30px' }}>
                <CourseHearingScript user={user} />
              </TabPane>
              <TabPane tab="Photos" key="photos" style={{ padding: '0 30px' }}>
                <Carousel
                  centerMode
                  ref={carouselEl}
                  dots
                  arrows
                  nextArrow={<RightCircleTwoTone />}
                  prevArrow={<LeftCircleTwoTone />}
                >
                  {videoCall.student.onboarding &&
                  videoCall.student.onboarding.idImage1 ? (
                    <div>
                      <img
                        src={videoCall.student.onboarding.idImage1.url}
                        alt="user"
                        style={{
                          textAlign: 'center',
                          display: 'inline-blocks',
                        }}
                      />
                    </div>
                  ) : null}
                  {videoCall.student.onboarding &&
                  videoCall.student.onboarding.idImage2 ? (
                    <div>
                      <img
                        src={videoCall.student.onboarding.idImage2.url}
                        alt="user"
                        style={{
                          textAlign: 'center',
                          display: 'inline-blocks',
                        }}
                      />
                    </div>
                  ) : null}
                  {photos.map(photo => (
                    <div>
                      <img
                        src={photo.dataUrl}
                        alt="user"
                        style={{
                          textAlign: 'center',
                          display: 'inline-blocks',
                        }}
                      />
                    </div>
                  ))}
                </Carousel>
              </TabPane>
              <TabPane tab="Onboarding" key="onboarding">
                {videoCall.student.onboarding && (
                  <div>
                    <div style={{ padding: '16px 0', textAlign: 'center' }}>
                      <p>
                        {isUserRegistered(videoCall.student.onboarding)
                          ? 'This student is registered'
                          : 'Please register this student'}
                      </p>
                      <Button
                        type="primary"
                        onClick={() => registerStudent()}
                        loading={loading}
                        disabled={registered}
                      >
                        Register student
                      </Button>
                    </div>
                    <OnboardingComponent
                      user={user}
                      onboarding={videoCall.student.onboarding}
                    />
                  </div>
                )}
              </TabPane>
              <TabPane tab="Objectives" key="reviewcourse">
                <StudentCourseReview
                  courseId={videoCall.course.id}
                  studentId={videoCall.student.id}
                />
              </TabPane>
              <TabPane
                tab="Chat"
                key="Chat"
                style={{ border: '1px solid #e8e8e8' }}
              >
                <Layout>
                  {/* <Header
                    style={{
                      background: '#fff',
                      paddingLeft: 12,
                      borderBottom: '1px solid #ddd',
                      position: 'fixed',
                      zIndex: 1,
                      width: '100%',
                    }}
                  ></Header> */}
                  <Content
                    style={{
                      padding: 12,
                      paddingTop: 72,
                      background: '#fff',
                      overflow: 'auto',
                    }}
                  >
                    {!selectedChat && (
                      <div style={{ textAlign: 'center', marginTop: '150px' }}>
                        <h3>Please select chat on the left to see messages.</h3>
                      </div>
                    )}
                    {selectedChat &&
                      selectedChat &&
                      selectedChat.map((message: any) => (
                        <div
                          key={message.id}
                          style={{
                            margin: '6px 0',
                            textAlign: isMyMessage(message) ? 'right' : 'left',
                          }}
                        >
                          <div
                            style={{
                              padding: '6px 12px',
                              border: '1px solid #ddd',
                              background: isMyMessage(message)
                                ? '#46A5E4'
                                : '#bbb',
                              display: 'inline-block',
                              borderRadius: 8,
                              color: '#fff',
                            }}
                          >
                            <p style={{ margin: 0 }}>{message.text}</p>
                            <span>
                              <small>
                                {moment(message.createdAt).fromNow()}
                              </small>
                            </span>
                          </div>
                        </div>
                      ))}
                  </Content>
                  <Footer
                    style={{
                      background: '#fff',
                      paddingLeft: 12,
                      borderTop: '1px solid #ddd',
                      zIndex: 1,
                      width: '100%',
                    }}
                  >
                    {selectedChat && (
                      <Input.Search
                        placeholder="Enter message"
                        enterButton="Send"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        onSearch={handleSendMessage}
                        width="100%"
                      />
                    )}
                  </Footer>
                </Layout>
              </TabPane>
            </Tabs>
          )}
          {user.role == Role.Student && (
            <Tabs defaultActiveKey="Chat">
              <TabPane
                tab="Chat"
                key="Chat"
                style={{ border: '1px solid #e8e8e8' }}
              >
                <Layout>
                  {/* <Header
                    style={{
                      background: '#fff',
                      paddingLeft: 12,
                      borderBottom: '1px solid #ddd',
                      position: 'fixed',
                      zIndex: 1,
                      width: '100%',
                    }}
                  ></Header> */}
                  <Content
                    style={{
                      padding: 12,
                      paddingTop: 72,
                      background: '#fff',
                      overflow: 'auto',
                    }}
                  >
                    {!selectedChat && (
                      <div style={{ textAlign: 'center', marginTop: '150px' }}>
                        <h3>Please select chat on the left to see messages.</h3>
                      </div>
                    )}
                    {selectedChat &&
                      selectedChat &&
                      selectedChat.map((message: any) => (
                        <div
                          key={message.id}
                          style={{
                            margin: '6px 0',
                            textAlign: isMyMessage(message) ? 'right' : 'left',
                          }}
                        >
                          <div
                            style={{
                              padding: '6px 12px',
                              border: '1px solid #ddd',
                              background: isMyMessage(message)
                                ? '#46A5E4'
                                : '#bbb',
                              display: 'inline-block',
                              borderRadius: 8,
                              color: '#fff',
                            }}
                          >
                            <p style={{ margin: 0 }}>{message.text}</p>
                            <span>
                              <small>
                                {moment(message.createdAt).fromNow()}
                              </small>
                            </span>
                          </div>
                        </div>
                      ))}
                  </Content>
                  <Footer
                    style={{
                      background: '#fff',
                      paddingLeft: 12,
                      borderTop: '1px solid #ddd',
                      zIndex: 1,
                      width: '100%',
                    }}
                  >
                    {selectedChat && (
                      <Input.Search
                        placeholder="Enter message"
                        enterButton="Send"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        onSearch={handleSendMessage}
                        width="100%"
                      />
                    )}
                  </Footer>
                </Layout>
              </TabPane>
            </Tabs>
          )}
        </div>
      )}
    </Modal>
  );
};

export default VideoChatModal;
