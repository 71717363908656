import React, { FunctionComponent, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Button, PageHeader, Row, Card, Col, Form } from 'antd';
import { toast } from 'react-toastify';
import LessonService from '@services/lessonService';
import { useHistory } from 'react-router';
import ChildrenItemsForm from '@components/forms/ChildrenItemsForm';
import ProjectsForm from '@components/forms/ProjectsForm';
import ReadingsForm from '@components/forms/ReadingsForm';
import AIIcon from '@assets/AI_with_notes.webp';
import CourseService from '@services/courseService';
import {
  normalizeProjects,
  normalizeReadingts,
} from '@components/forms/helpers';

interface LessonFormProps {
  lesson: Partial<Lesson>;
}

const LessonForm: FunctionComponent<LessonFormProps> = ({ lesson }) => {
  const history = useHistory();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [objectives, setObjectives] = useState<Objective[]>(
    lesson.objectives || [],
  );
  const [projects, setProjects] = useState<Project[]>(lesson.projects || []);
  const [readings, setReadings] = useState<Reading[]>(lesson.readings || []);
  const [longDescription, setLongDescription] = useState(
    lesson.longDescription,
  );
  const [syllabusLoading, setSyllabusLoading] = useState(false);
  const [shortDesLoading, setShortDesLoading] = useState(false);
  const [longDesLoading, setLongDesLoading] = useState(false);

  const [objectivesAILessons, setObjectivesAILessons] = useState<any>([]);
  const [topicsAILessons, setTopicsAILessons] = useState<any>({
    prompt: {
      courseName: '',
      lessonInfo: '',
      lessonName: '',
      userIdentifier: '',
    },
    topics: [],
  });
  const [lessonDemoAI, setLessonDemoAI] = useState<any>([]);
  const [form] = Form.useForm();
  const { validateFields, setFieldsValue } = form;

  console.log(objectivesAILessons);

  const handleSubmit = () => {
    validateFields().then(values => {
      if (lesson.id) {
        // setUpdateLoading(true);

        const normalizedProjects = projects ? normalizeProjects(projects) : [];
        const normalizedReadings = readings ? normalizeReadingts(readings) : [];
        const savePromise =
          lesson.id === -1
            ? LessonService.create({
                ...values,
                longDescription,
                objectives,
                projects: normalizedProjects as Project[],
                readings: normalizedReadings as Reading[],
              })
            : LessonService.update(lesson.id, {
                ...values,
                longDescription,
                objectives,
                id: lesson.id,
                projects: normalizedProjects as Project[],
                readings: normalizedReadings as Reading[],
              });

        savePromise
          .then(d => {
            toast.success('Update successful!');
            history.push(`/lessons/${d.id}`);
          })
          .catch(() => {
            toast.error('Update failed!');
            setUpdateLoading(false);
          });
      }
    });
  };

  const AILessonSyllabusGet = async () => {
    try {
      const lessonName = form.getFieldValue('name') || lesson.name;
      if (lessonName === '' || !lessonName) {
        return;
      }
      const sendData = {
        courseName: '',
        lessonName,
        lessonInfo: '',
        userIdentifier: '',
      };
      setSyllabusLoading(true);
      const LessonSyllabus = await CourseService.getCourseLessonSyllabusAI(
        sendData,
      );
      if (LessonSyllabus.success) {
        setSyllabusLoading(false);
        const sroreList1: { id: number; text: string }[] = [];
        if (LessonSyllabus.data.completion.objectives.length) {
          LessonSyllabus.data.completion.objectives.map((item: any) => {
            sroreList1.push({ id: -1, text: item });
          });
          setObjectivesAILessons(sroreList1);
          setTopicsAILessons({
            prompt: LessonSyllabus.prompt,
            topics: LessonSyllabus.data.completion.topics,
          });
        } else {
          setSyllabusLoading(false);
        }
      } else {
        setSyllabusLoading(false);
      }
    } catch (err) {
      setSyllabusLoading(false);
    }
  };
  const handleRemoveObjectives = (index: number) => {
    const newData = objectivesAILessons;
    if (newData[index]) {
      newData.splice(index, 1);
    }
    setObjectivesAILessons([...newData]);
  };
  const handleAddObjectives = (index: number, obj: any) => {
    if (obj.text == '' || obj.text == undefined) {
      return;
    }
    setLessonDemoAI({ courseName: '', lessionNameAI: obj.text });
    handleRemoveObjectives(index);
  };

  const AICourseLessonShortDesGet = async () => {
    try {
      const lessonName = form.getFieldValue('name') || lesson.name;
      if (lessonName === '' || !lessonName) {
        return;
      }
      const sendData = {
        courseName: '',
        lessonName,
        lessonObjectives: objectivesAILessons
          .map((o: any) => o.text)
          .join(',')
          .replace(/,/g, '\n-'),
        userIdentifier: '',
      };
      setShortDesLoading(true);
      const LessonCourseSortDes = await CourseService.getCourseLessonSortDesAI(
        sendData,
      );
      if (LessonCourseSortDes.success) {
        setShortDesLoading(false);
        form.setFieldsValue({
          shortDescription: LessonCourseSortDes.data.completion,
        });
      }
    } catch (err) {}
  };
  const AICourseLessonLongDesGet = async () => {
    try {
      const lessonName = form.getFieldValue('name') || lesson.name;
      if (lessonName === '' || !lessonName) {
        return;
      }
      const sendData = {
        courseName: '',
        lessonName,
        lessonObjectives: objectivesAILessons.toString().replace(/,/g, '\n-'),
        userIdentifier: '',
      };
      setLongDesLoading(true);
      const LessonCourseLongDes = await CourseService.getCourseLessonLongDesAI(
        sendData,
      );
      if (LessonCourseLongDes.success) {
        setLongDesLoading(false);
        setLongDescription(LessonCourseLongDes.data.completion[0]);
      }
    } catch (err) {
      // console.log('catch err', err);
    }
  };
  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Row gutter={[16, { xs: 16, sm: 16 }]}>
        <Col lg={16} md={14}>
          <PageHeader
            style={{
              background: '#fff',
              padding: '24px 16px 16px',
            }}
            extra={[
              <Button
                key="save"
                type="primary"
                loading={updateLoading}
                onClick={handleSubmit}
              >
                Save
              </Button>,
            ]}
            title={
              <>
                <h2 style={{ marginBottom: 0 }}>
                  {lesson.id === -1 ? 'Create' : 'Edit'} Lesson
                </h2>
              </>
            }
          />
          <div
            style={{
              background: '#fff',
              padding: '16px 16px',
            }}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: 'This field is required!',
                },
              ]}
              initialValue={lesson.name}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="introVideo"
              initialValue={lesson.introVideo}
              label="Intro video"
            >
              <Input />
            </Form.Item>
            <Button
              className="ai-btn-short-des"
              disabled={objectivesAILessons.length === 0}
              type="primary"
              onClick={() => {
                AICourseLessonShortDesGet();
              }}
              loading={shortDesLoading}
            >
              {!shortDesLoading && (
                <img
                  className={shortDesLoading ? 'AIIcon-loding' : ''}
                  style={{
                    height: '35px',
                    width: '35px',
                  }}
                  src={AIIcon}
                />
              )}
            </Button>
            <Form.Item
              name="shortDescription"
              label="Short description"
              rules={[
                {
                  required: true,
                  message: 'This field is required!',
                },
              ]}
              initialValue={lesson.shortDescription}
            >
              <Input.TextArea />
            </Form.Item>
            <div className="ant-col ant-form-item-label">
              <label
                className="ant-form-item-required"
                title="Long description"
              >
                Long description
              </label>
              <span>
                <Button
                  type="primary"
                  disabled={objectivesAILessons.length === 0}
                  onClick={() => {
                    AICourseLessonLongDesGet();
                  }}
                  loading={longDesLoading}
                  className="ai-btn-long-des"
                >
                  <img
                    style={{
                      height: '35px',
                      width: '35px',
                    }}
                    src={AIIcon}
                  />
                </Button>
              </span>
            </div>
            <ReactQuill
              value={longDescription || ''}
              onChange={(value: string) => {
                setLongDescription(value);
              }}
            />

            <Button
              loading={syllabusLoading}
              className="ai-btn-lesson-objectives"
              type="primary"
            >
              {' '}
              {!syllabusLoading && (
                <img
                  style={{
                    height: '35px',
                    width: '35px',
                  }}
                  onClick={() => {
                    AILessonSyllabusGet();
                  }}
                  src={AIIcon}
                />
              )}
            </Button>
            <ChildrenItemsForm<Objective>
              type="objective"
              items={objectives}
              setItems={setObjectives}
              setFieldsValue={setFieldsValue}
              showCreate
              lessionAI={lessonDemoAI}
            />
            <div style={{ marginTop: '15px' }}>
              <ul>
                {objectivesAILessons.map((objective: any, index: any) => (
                  <li
                    key={`${objective.id}#${index}`}
                    style={{ marginTop: '15px' }}
                  >
                    <>
                      {objective.text}
                      <Button
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                        danger
                        size="small"
                        ghost
                        onClick={() => {
                          handleRemoveObjectives(index);
                        }}
                      >
                        <CloseOutlined />
                      </Button>
                      <Button
                        onClick={() => {
                          handleAddObjectives(index, objective);
                        }}
                        className="ai-comman-btn-pulse"
                        size="small"
                      >
                        <PlusOutlined
                          style={{ color: '#46a5e5' }}
                          className="key_pluse"
                        />
                      </Button>
                    </>
                  </li>
                ))}
              </ul>
            </div>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={updateLoading}>
                Save
              </Button>
            </Form.Item>
          </div>
        </Col>
        <Col lg={8} md={10}>
          <Card size="small" title="Exercises" className="sidebar-widget">
            <ProjectsForm
              topicsAILessons={topicsAILessons}
              projects={projects}
              setProjects={setProjects}
            />
          </Card>
          <Card size="small" title="Readings" className="sidebar-widget">
            <ReadingsForm
              readings={readings}
              setReadings={setReadings}
              getFieldDecorator={() => {}}
            />
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default LessonForm;
