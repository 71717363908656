import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
  Table,
  Input,
  Select,
  Card,
  Alert,
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactQuill from 'react-quill';
import { AppState } from '@redux/reducers';
import AgreementService from '@services/AgreementService';
import { ColumnProps } from 'antd/lib/table';

interface AdminProps {
  user?: User;
}

const Admin: React.FC<AdminProps> = ({ user }) => {
  const [newAgreement, setNewAgreement] = useState<Partial<Agreement>>({
    type: 'studentAgreement',
  });
  const [agreements, setAgreements] = useState<Agreement[]>();
  const [agreementModalVisible, setAgreementModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const agreementColumns: ColumnProps<Agreement>[] = [
    { key: 'type', dataIndex: 'type', title: 'Type' },
    { key: 'title', dataIndex: 'title', title: 'Title' },
    {
      title: 'Last updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt?: Date) =>
        updatedAt ? moment(updatedAt).format('L') : '-',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt?: Date) =>
        createdAt ? moment(createdAt).format('L') : '-',
    },
    {
      key: 'actions',
      render: (text, agreement) => (
        <div>
          <Button
            type="default"
            onClick={() => {
              setNewAgreement(agreement);
              setAgreementModalVisible(true);
            }}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const getAgreements = () => {
    setLoading(true);
    AgreementService.getAll()
      .then(response => {
        setError(undefined);
        setLoading(false);
        setAgreements(response);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  };

  const updateAgreement = () => {
    setError(undefined);
    if (!newAgreement.type || !newAgreement.text) {
      setError('Please enter text and type');
      return;
    }
    setLoading(true);
    AgreementService.update(newAgreement)
      .then(() => {
        setAgreementModalVisible(false);
        setNewAgreement({});
        setLoading(false);
        getAgreements();
      })
      .catch((err: { message: string }) => {
        console.error(err);
        setLoading(false);
        setError(err.message);
      });
  };

  useEffect(() => {
    if (!agreements && !loading) {
      getAgreements();
    }
  }, [agreements, loading]);

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title="Agreements"
          size="small"
          // extra={
          //   <Button
          //     type="primary"
          //     onClick={() => setAgreementModalVisible(true)}
          //   >
          //     Add agreement
          //   </Button>
          // }
        >
          <Table
            dataSource={agreements}
            columns={agreementColumns}
            rowKey="id"
          />
        </Card>
      </Col>
      <Modal
        title="Edit agreement"
        width={720}
        open={agreementModalVisible}
        okText="Save agreement"
        okButtonProps={{ loading }}
        onOk={() => updateAgreement()}
        onCancel={() => setAgreementModalVisible(false)}
      >
        {error && (
          <Alert message={error} type="error" style={{ marginBottom: 16 }} />
        )}
        <div>
          <p style={{ margin: '0 0 8px 0' }}>Agreement title:</p>
          <Input
            placeholder="Agreement title"
            value={newAgreement.title}
            onChange={(e: any) => {
              setNewAgreement({ ...newAgreement, title: e.target.value });
            }}
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <p style={{ margin: '0 0 8px 0' }}>Agreement type:</p>
          <Select
            placeholder="Agreement duration"
            value={newAgreement.type}
            onSelect={(value: any) => {
              setNewAgreement({ ...newAgreement, type: value });
            }}
          >
            <Select.Option key="termsOfUse" value="termsOfUse">
              termsOfUse
            </Select.Option>
            <Select.Option key="userAgreement" value="userAgreement">
              userAgreement
            </Select.Option>
            <Select.Option key="studentAgreement" value="studentAgreement">
              studentAgreement
            </Select.Option>
            {/* <Select.Option key="privacyPolicy" value="privacyPolicy">
              privacyPolicy
            </Select.Option> */}
          </Select>
        </div>
        <div style={{ marginTop: 16 }}>
          <p style={{ margin: '0 0 8px 0' }}>Text:</p>
          <ReactQuill
            value={newAgreement.text || ''}
            onChange={(value: string) => {
              setNewAgreement({
                ...newAgreement,
                text: value,
              });
            }}
          />
        </div>
      </Modal>
    </Row>
  );
};

const selectAdminState = (state: AppState) => ({
  user: state.user.user,
});

const ConnectedAdmin = connect(selectAdminState, {})(Admin);

const Page = () => <ConnectedAdmin />;

export default Page;
