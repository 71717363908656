import React, { useEffect } from 'react';
import { Input, Button, Divider, Checkbox, Form, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

interface AssessmentFormProps {
  assessments: Assessment[];
  setAssessments: any;
  setFieldsValue: any;
  assessmentsAILessons: [
    {
      question: '';
      answers: [
        {
          text: '';
          correct: false;
        },
      ];
    },
  ];
}

const AssessmentForm: React.FC<AssessmentFormProps> = ({
  assessments,
  setAssessments,
  setFieldsValue,
  assessmentsAILessons,
}) => {
  useEffect(() => {
    if (assessmentsAILessons && assessmentsAILessons.length > 1) {
      createItem();
    }
  }, [assessmentsAILessons]);
  const createItem = () => {
    const sroreList: {
      id: number;
      type: string;
      question: string;
      answers: { id: number; correct: boolean; text: string }[];
    }[] = [];
    if (assessmentsAILessons.length > 0) {
      assessmentsAILessons.map((AItopic: any) => {
        // const arrWithId = AItopic.answers.map((object: any) => {
        //   return { ...object, id: -1 };
        // });

        sroreList.push({
          id: -1,
          type: 'multiChoice',
          question: AItopic.question,
          // answers: arrWithId,
          answers: AItopic.answers,
        });
      });
      // console.log('sroreList', sroreList);
      // setAssessments(sroreList);
      setAssessments([...assessments, ...sroreList]);
      setFieldsValue({
        assessments: [...assessments, ...sroreList],
      });
    }
  };

  return (
    <>
      <h3>Objective assessments</h3>

      <Form.List name="assessments" initialValue={assessments}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>
                {assessments[name]?.id !== -1 && (
                  <Form.Item name={[name, 'id']} hidden>
                    <Input hidden />
                  </Form.Item>
                )}
                <Form.Item
                  name={[name, 'question']}
                  label="Question"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required!',
                    },
                  ]}
                >
                  <Input.TextArea rows={2} />
                </Form.Item>
                <Form.List
                  name={[name, 'answers']}
                  initialValue={assessments[name]?.answers}
                >
                  {(
                    answerFields,
                    { add: addAnswer, remove: removeAnswer },
                    { errors: answerErrors },
                  ) => (
                    <>
                      <h4>Answers</h4>
                      {answerFields.map(
                        ({
                          key: answerKey,
                          name: answerName,
                          ...restAnswerField
                        }) => (
                          <Space
                            key={answerKey}
                            style={{ display: 'flex', marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item name={[answerName, 'id']} hidden>
                              <Input hidden />
                            </Form.Item>
                            <Form.Item
                              {...restAnswerField}
                              name={[answerName, 'text']}
                              label="Answer text"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required!',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              {...restAnswerField}
                              name={[answerName, 'correct']}
                              wrapperCol={{ span: 12 }}
                              valuePropName="checked"
                            >
                              <Checkbox defaultChecked={false}>
                                Correct
                              </Checkbox>
                            </Form.Item>
                            <Button
                              danger
                              size="small"
                              ghost
                              onClick={() => removeAnswer(answerName)}
                            >
                              <CloseOutlined />
                            </Button>
                          </Space>
                        ),
                      )}
                      <Button
                        type="primary"
                        size="small"
                        ghost
                        onClick={() => addAnswer(name)}
                      >
                        Add answer
                      </Button>
                    </>
                  )}
                </Form.List>

                <br />
                <br />
                <Button danger size="small" ghost onClick={() => remove(name)}>
                  Delete assessment
                </Button>
                <Divider />
              </div>
            ))}
            <Button type="primary" onClick={() => add()}>
              Add new assessment
            </Button>
          </>
        )}
      </Form.List>
    </>
  );
};

export default AssessmentForm;
