import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = 'https://api.newlaneuniversity.com';
const API_AI_URL =
  process.env.REACT_APP_API_AI_URL || 'https://ai-api.newlaneuniversity.com';

const ApiService = {
  get: async (path: string, params?: any): Promise<any> => {
    const token = localStorage.getItem('accessToken');
    return axios.get(`${API_URL}${path}`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  post: async (path: string, data: any): Promise<any> => {
    const token = localStorage.getItem('accessToken');
    return axios
      .post(`${API_URL}${path}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .catch(err => Promise.reject(err.response));
  },

  put: async (path: string, data: any): Promise<any> => {
    const token = localStorage.getItem('accessToken');

    if (data.courses) {
      const degree = data;
      degree.courses = data.courses.map((course: Course) => ({
        id: course.id,
      }));
      return axios.put(`${API_URL}${path}`, degree, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return axios.put(`${API_URL}${path}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  },

  delete: async (path: string): Promise<any> => {
    const token = localStorage.getItem('accessToken');
    return axios.delete(`${API_URL}${path}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  },
  postAI: async (path: string, data: any): Promise<any> => {
    // console.log('Main Servise call')
    const token = localStorage.getItem('accessToken');
    return axios
      .post(`${API_AI_URL}${path}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .catch(err => Promise.reject(err.response));
  },
  getUrl: (path: string) => `${process.env.REACT_APP_API_URL}${path}`,

  getHeaders: () => {
    const token = localStorage.getItem('accessToken');
    return {
      Authorization: `Bearer ${token}`,
    };
  },
};

export default ApiService;
